<template>
  <div class="task_detail_container">
    <div class="title_container">
      <div class="back_to_task" @click="backToTaskHall">
        <i class="el-icon-arrow-left"></i>
        <span>返回上页</span>
      </div>
      <div class="task_title">
        <div class="title">企业认证</div>
      </div>
    </div>
    <el-form
      class="task_allInfo_container"
      ref="form"
      label-position="left"
      label-width="110px"
      :model="form">
      <el-form-item
        label="企业名称">
        <el-input
          type="text"
          size="small"
          v-model.trim="form.companyName"
          style="width:320px;">
        </el-input>
      </el-form-item>
      <el-form-item
        label="联系邮箱">
        <el-input
          type="text"
          size="small"
          style="width:320px;"
          v-model.trim="form.companyEmail">
        </el-input>
      </el-form-item>
      <el-form-item
        label="联系电话">
        <el-input type="text"
          maxlength="11"
          size="small"
          v-model.trim="form.companyTelephone"
          style="width:320px;">
        </el-input>
      </el-form-item>
      <el-form-item
        label="营业执照">
        <el-image
          :src="imgUrl"
          fit="fill"
          :preview-src-list="imgList"
          style="width: 160px;height: 160px;">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
      </el-form-item>
      <el-form-item v-if="form.authing !== COMPANY_STATUS.VERIFIED">
        <el-button
          type="primary"
          size="small"
          @click="confirm"
          :loading="btnLoading">
          确认认证
        </el-button>
        <el-button
          size="small"
          plain
          @click="cancel"
          type="danger">
          驳回
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '@/api/index'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      form: {},
      imgUrl: '',
      imgList: [],
      btnLoading: false
    }
  },
  created () {
    this.getDetailInfo()
  },
  computed: {
    companyId: {
      get () {
        return this.$route.params.companyId
      }
    },
    COMPANY_STATUS () {
      return globalVar.COMPANY_STATUS
    }
  },
  methods: {
    getDetailInfo () {
      api.adminUserDetail(this.companyId).then(res => {
        if (res.data.code === 0) {
          this.form = res.data.data
          this.imgUrl = res.data.data.companyLicence !== null ? res.data.data.companyLicence.url : ''
          this.imgList.push(this.imgUrl)
        } else {
          this.$message.error('请求错误')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    },
    backToTaskHall () {
      this.$router.push('/admin/certification')
    },
    // 企业审核确认
    confirm () {
      this.btnLoading = true
      api.adminCertification(this.form.id, {
        action: 'pass'
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('认证成功')
          this.$router.push('/admin/certification')
        } else {
          this.$message.error('认证失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      }).finally(() => {
        this.btnLoading = false
      })
    },
    cancel () {
      api.adminCertification(this.form.id, {
        action: 'reject'
      }).then(res => {
        if (res.data.code === 0) {
          this.$message.success('驳回成功')
          this.$router.push('/admin/certification')
        } else {
          this.$message.error('驳回失败')
        }
      }).catch((err) => {
        console.log(err)
        this.$message.error('请求异常')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.task_detail_container {
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  overflow: auto;
  .title_container {
    height: 100px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E4E7ED;
    .back_to_task {
      width: 100px;
      height: 40px;
      margin-right: 30px;
      font-size: 14px;
      line-height: 40px;
      font-weight: 500;
      color: #606266;
      cursor: pointer;
    }
    .task_title {
      flex: 1;
      .title {
        font-size: 20px;
        font-weight: bold;
        color: #303133;
      }
      .time {
        font-size: 14px;
        color: #909399;
      }
    }
  }
  .task_allInfo_container {
    padding: 30px 50px;
  }
}
</style>
